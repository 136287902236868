<template>
    <div>
        <h2>{{ Content.title }}</h2>
        <div class="mb-2"><span id="refresh-title">Zadnja osvežitev podatkov: </span> <span id="refresh-date">{{ lastRefresh.lastRefresh }}</span></div>
        <div><span id="station-type-title">Tip vremenske postaje: </span> <span id="station-type-value">{{ Content.type }}</span></div>
        <div><span id="sea-level-title">Nadmorska višina: </span> <span>{{ Content.attitude }} m</span></div>
    </div>
</template>

<script>
export default {
    name: 'Content',
    props: {
        Content: Object,
        lastRefresh: Object
    },
}
</script>

<style scoped>
h2 {
    color: rgb(35, 35, 206);
    font-weight: 700;
}

#refresh-title {
    font-size: 1.15rem;
    font-weight: 700;
}

#refresh-date {
    font-size: 1.10rem;
    font-weight: 600;
}

#station-type-title {
    font-size: 1.0rem;
    font-weight: 700;
}

#sea-level-title {
    font-size: 1.0rem;
    font-weight: 700;
}
</style>