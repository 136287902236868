<template>
    <div class="container-xl mt-2">
        <div class="row text-center mb-2">
            <div class="col-12 col-xl-2 offset-xl-1">
                <StationImage :src="url"/>
            </div>
            <div class="col-12 col-xl-6">
                <StationContent :Content="detailsContent" :lastRefresh="GetDetailsData"/>
            </div>
            <div class="col-12 col-xl-2 align-self-center">
                <StationSwitcher :SwitcherIncluded="switcherIncluded"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-xl-5 offset-xl-1" v-if="graphIncluded.temp">
                <StationDetails 
                :data="data.temperature" 
                :chartOptions="GetDetailsData.temp"/>
            </div>
            <div class="col-12 col-xl-5" v-if="graphIncluded.hum">
                <StationDetails 
                :data="data.humidity" 
                :chartOptions="GetDetailsData.hum"/>
            </div>
        </div>
         <div class="row">
            <div class="col-12 col-xl-5 offset-xl-1" v-if="graphIncluded.pressure">
                <StationDetails 
                :data="data.pressure" 
                :chartOptions="GetDetailsData.pressure"/>
            </div>
            <div class="col-12 col-xl-5" v-if="graphIncluded.rain && graphIncluded.pressure == true">
                <StationDetails 
                :data="data.rain" 
                :chartOptions="GetDetailsData.rain"/>
            </div>
            <div class="col-12 col-xl-5" v-if="graphIncluded.svAna">
                 <StationDetails 
                :data="data.dewpoint" 
                :chartOptions="GetDetailsData.dewpoint"/>
            </div>
        </div>
         <div class="row">
            <div class="col-12 col-xl-5 offset-xl-1" v-if="graphIncluded.wind">
                <StationDetails 
                :data="data.windSpeed" 
                :chartOptions="GetDetailsData.wind"/>
            </div>
            <div class="col-12 col-xl-5" v-if="graphIncluded.dewpoint && graphIncluded.wind">
                <StationDetails 
                :data="data.dewpoint" 
                :chartOptions="GetDetailsData.dewpoint"/>
            </div>
            <div v-else-if="graphIncluded.dewpoint && graphIncluded.svAna == false" class="col-12 col-xl-5 offset-xl-1">
                 <StationDetails 
                :data="data.dewpoint" 
                :chartOptions="GetDetailsData.dewpoint"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-xl-5 offset-xl-1" v-if="graphIncluded.uv">
                <StationDetails 
                :data="data.uv" 
                :chartOptions="GetDetailsData.uv"/>
            </div>
            <div class="col-12 col-xl-5" v-if="graphIncluded.uvWithRain == true">
                <StationDetails 
                :data="data.rain" 
                :chartOptions="GetDetailsData.rain"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-xl-5 offset-xl-1" v-if="graphIncluded.sodrazica">
                <StationDetails 
                :data="data.rain" 
                :chartOptions="GetDetailsData.rain"/>
            </div>
        </div>
    </div>
</template>

<script>
import StationDetails from '../components/Details/Station/Details.vue'
import StationImage from '../components/Details/Station/Image.vue'
import StationContent from '../components/Details/Station/Content.vue'
import StationSwitcher from '../components/Details/Station/Switcher.vue'
import { Settings } from '../Data/Data';
import { WeatherChartData } from "../Data/FakeData";
import { ChartSettings } from '../Data/Data';
import router from '../router/index'

export default {
    name: 'Details',
    data() {
        return {
            data: Settings,
            WeatherChartData: WeatherChartData,
            ChartSettings: ChartSettings,
            graphIncluded: {
                temp: false,
                hum: false,
                pressure: false,
                rain: false,
                wind: false,
                dewpoint: false,
                uv: false,
                uvWithRain: false,
                svAna: false,
                sodrazica: false
            },
            detailsContent: {
                title: '',
                refresh: null,
                type: null,
                attitude: null
            },
            switcherIncluded: {
                day: true,
                month: true,
                week: true
            },
            urL: ''
        }
    },
    computed: {
        GetDetailsData() {
            var details = {
                temp: this.$store.getters.detailsChartOptions,
                hum: this.$store.getters.humidityChartOptions,
                pressure: this.$store.getters.pressureChartOptions,
                rain: this.$store.getters.rainChartOptions,
                wind: this.$store.getters.windChartOptions,
                dewpoint: this.$store.getters.dewpointChartOptions,
                uv: this.$store.getters.uvChartOptions,
                lastRefresh: this.$store.getters.refreshTime
            }
            return details;
        },
    },
    created() {  
        var stationId = router.currentRoute._value.params.stationId
        if (stationId === 'LYk4p9r8RA') {
            this.graphIncluded.temp = true;
            this.graphIncluded.hum = true;
            this.graphIncluded.pressure = true;
            this.graphIncluded.rain = true;
            this.graphIncluded.wind = true;
            this.graphIncluded.dewpoint = true;
            this.graphIncluded.uv = false;

            this.detailsContent.title = 'Vremenska postaja Hrib - Loški Potok';
            this.detailsContent.type = 'višinska';
            this.detailsContent.attitude = 773;

            this.url = 'https://postaje.vreme-podnebje.si/images/details/hrib.jpg';
        }
        else if (stationId === 'NxEkuvG2VT') {
            this.graphIncluded.temp = true;
            this.graphIncluded.hum = true;
            this.graphIncluded.pressure = false;
            this.graphIncluded.rain = false;
            this.graphIncluded.wind = false;
            this.graphIncluded.dewpoint = true;
            this.graphIncluded.uv = false;

            this.detailsContent.title = 'Vremenska postaja Retje';
            this.detailsContent.type = 'mraziščarska';
            this.detailsContent.attitude = 705;

            this.url = 'https://postaje.vreme-podnebje.si/images/details/retje.jpg';
        }
        else if (stationId === 'Cy3kas34kV') {
            this.graphIncluded.temp = true;
            this.graphIncluded.hum = true;
            this.graphIncluded.pressure = false;
            this.graphIncluded.rain = true;
            this.graphIncluded.wind = true;
            this.graphIncluded.dewpoint = true;
            this.graphIncluded.uv = false;

            this.detailsContent.title = 'Vremenska postaja Ribnica';
            this.detailsContent.type = 'nižinska';
            this.detailsContent.attitude = 492;

            this.url = 'https://onsetcompcdn.s3-us-west-2.amazonaws.com/assets/onset/pages/weather-stations-lp/weather-stations.jpg';
        }
        else if (stationId === 'KMn2x1KsWgQ') {
            this.graphIncluded.temp = true;
            this.graphIncluded.hum = true;
            this.graphIncluded.pressure = true;
            this.graphIncluded.rain = true;
            this.graphIncluded.wind = true;
            this.graphIncluded.dewpoint = true;
            this.graphIncluded.uv = true;

            this.detailsContent.title = 'Vremenska postaja Prigorica';
            this.detailsContent.type = 'nižinska';
            this.detailsContent.attitude = 486;

            this.url = 'https://postaje.vreme-podnebje.si/images/details/prigorica.jpg';
        }
        else if (stationId === 'HkumAw2XoQs') {
            this.graphIncluded.temp = true;
            this.graphIncluded.hum = true;
            this.graphIncluded.rain = true;
            this.graphIncluded.wind = true;
            this.graphIncluded.dewpoint = true;
            this.graphIncluded.uv = true;
            this.graphIncluded.uvWithRain = true;

            this.detailsContent.title = 'Vremenska postaja Gašpinovo (Slemena)';
            this.detailsContent.type = 'višinska';
            this.detailsContent.attitude = 721;

            this.url = 'https://postaje.vreme-podnebje.si/images/details/gaspinovo.jpg';

            this.switcherIncluded.week = false;
            this.switcherIncluded.month = false;
        }
        else if (stationId === 'Rds23w5XoyD') {
            this.graphIncluded.temp = true;
            this.graphIncluded.hum = true;
            this.graphIncluded.pressure = true;
            this.graphIncluded.rain = false;
            this.graphIncluded.wind = false;
            this.graphIncluded.dewpoint = true;
            this.graphIncluded.uv = false;
            this.graphIncluded.svAna = true;

            this.detailsContent.title = 'Vremenska postaja Sveta Ana';
            this.detailsContent.type = 'višinska';
            this.detailsContent.attitude = 910;

            this.url = 'https://postaje.vreme-podnebje.si/images/details/svetaAna.png';

            this.switcherIncluded.week = true;
            this.switcherIncluded.month = true;
        }
        else if (stationId === 'Is6cqykhG1') {
            this.graphIncluded.temp = true;
            this.graphIncluded.hum = true;
            this.graphIncluded.pressure = false;
            this.graphIncluded.rain = true;
            this.graphIncluded.wind = true;
            this.graphIncluded.dewpoint = true;
            this.graphIncluded.uv = false;
            this.graphIncluded.svAna = false;
            this.graphIncluded.sodrazica = true;

            this.detailsContent.title = 'Vremenska postaja Izver (spodaj)';
            this.detailsContent.type = 'nižinska';
            this.detailsContent.attitude = 535;

            this.url = 'https://postaje.vreme-podnebje.si/images/details/izverSpodaj.png';

            this.switcherIncluded.week = false;
            this.switcherIncluded.month = false;
        }
        else if (stationId === '10s1ylZBdq0') {
            this.graphIncluded.temp = true;
            this.graphIncluded.hum = true;
            this.graphIncluded.pressure = false;
            this.graphIncluded.rain = true;
            this.graphIncluded.wind = true;
            this.graphIncluded.dewpoint = true;
            this.graphIncluded.uv = false;
            this.graphIncluded.svAna = false;
            this.graphIncluded.sodrazica = true;

            this.detailsContent.title = 'Vremenska postaja Izver (na vrhu)';
            this.detailsContent.type = 'višinska';
            this.detailsContent.attitude = 622;

            this.url = 'https://postaje.vreme-podnebje.si/images/details/izverZgoraj.jpg';

            this.switcherIncluded.week = false;
            this.switcherIncluded.month = false;
        }
        this.$store.dispatch("getDetailsData");
    },
    components: {
        StationDetails,
        StationImage,
        StationContent, 
        StationSwitcher,
    },
    methods: {

    }
}
</script>