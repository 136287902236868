export const WeatherChartData = {
    TemperatureChart: {
        serie1: [
            1, 1.3, 1.5, 1.8, 2.3, 2, 7, 3.3, 3.9, 4.7, 6.8, 7.7, 7.9, 8.3, 8.5, 8.9,
            10.0, 12.1, 12.1, 12.3, 12.5, 15.3, 15.5, 16.0, 16.5, 17.0, 17.1, 17.3,
            17.5, 18.1, 18.9, 19.3, 20.3, 24.3, 19.2, 18.9, 18.9, 18.9, 18.7, 18.5,
            18.3, 18.1, 17.9, 17.7, 18.2, 18.6, 17.3, 15.0, 14.9, 13, 9, 14.3, 13.9,
            13.5, 13.2, 13.0, 12.5, 12.0, 11.9, 11.7, 11.3, 9.9, 7.7, 5.3, 5.5, 5.0,
            4.8, 4.3, 4.2, 4.1, 3.8, 3.4, 3.2, 2.9, 2.0, 1.3, 0.7, -0.1, -0.3,
        ],
        serie2: [
            -1.1, -0.4, 1.2, 1.2, 4.2, 4.7, 8, 9.3, 9.9, 10.7, 10.8, 11.7, 11.9, 12.3,
            12.5, 12.9, 13.0, 13.1, 13.1, 13.3, 13.5, 16.3, 16.5, 17.0, 17.5, 18.0,
            19.1, 20.3, 20.5, 21.1, 22.9, 21.3, 19.3, 19.3, 18.2, 17.9, 16.9, 15.9,
            15.7, 14.5, 13.3, 15.1, 16.9, 15.7, 13.2, 11.6, 10.3, 10.0, 9.9, 8.9, 7.9,
            6.3, 6.0, 5.5, 5.2, 5.0, 4.5, 4.0, 3.9, 3.7, 3.3, 2.9, 2.7, 2.3, 2.5, 2.0,
            1.8, 1.3, 1.2, 1.1, 0.8, 0.4, 0.2, -1.9, -2.0, -2.3, -2.7, -1.1, 0.3,
          ],
    },
    HumidityChart: {
        serie1: [
            89.3, 89.9, 90.1, 90.9, 91.1, 91.1, 91.3, 91.5, 91.9, 92.2, 92.5, 92.8, 93.1,
            95.1, 96.7, 97.9, 95.3, 95.2, 95.1, 93.1, 87.3, 83.4, 80.1, 78.3, 76.1, 73.7, 
            71.1, 70.8, 68.1, 66.3, 64.1, 63.3, 60.9, 58.8, 56.1, 54.2, 51.4, 50.1, 49.9,
            49.8, 49.6, 49.4, 49.3, 49.0, 48.6, 48.4, 48.1, 47.9, 47.7, 47.3, 47.3, 47.2,
            48.2, 48.5, 48.9, 49.1, 49.3, 49.9, 51.1, 51.5, 51.9, 52.3, 52.5, 53.1, 53.3,
            56.8, 57.1, 63.4, 67.1, 68.3, 69.7, 70.2, 72.3, 73.1, 74.6, 82.3, 85.3, 86.9,
        ],
        serie2: []
    },
    PressureChart: {
        serie1: [
            1012.3, 1012.6, 1012.8, 1011.9, 1011.6, 1011.4, 1011.1, 1011.3, 1012.1, 1012.5,
            1013.9, 1014.1, 1013.9, 1013.8, 1014.1, 1014.7, 1015.1, 1015.7, 1015.9, 1016.2,
            1016.2, 1016.3, 1016.9, 1016.3, 1015.8, 1015.3, 1015.1, 1014.7, 1014.6, 1014.5,
            1013.9, 1013.5, 1013.9, 1014.1, 1014.3, 1014.9, 1014.1, 1014.3, 1014.2, 1014.9,
            1015.6, 1015.9, 1016.1, 1016.4, 1016.7, 1016.4, 1016.3, 1016.1, 1016.1, 1015.9,
            1014.3, 1014.6, 1014.9, 1014.2, 1014.7, 1015.1, 1014.2, 1014.1, 1013.0, 1013.9
        ],
        serie2: []
    },
    RainChart: {
        serie1: [
            0.0, 0.5, 0.0, 0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.2,
            0.1,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.6,0.0,0.0,0.0,0.0,0.0,0.0,0.0,
            0.8,0.0,0.0,0.0,0.0,0.0,0.0,0.0,1.2,0.0,0.0,0.0,0.0,0.0,0.0,0.1,0.0,0.0,0.0,
            1.8, 0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.3, 0.0,0.0,0.0,0.0,0.0,0.0,0.0,
        ],
    },
    WindChart: {
        serie1: [
            0.3, 0.6, 0.8, 0.2, 1.1, 0.9, 0.0, 0.3, 0.5, 1,1, 4.5, 5.6, 5.3, 5.1, 4.9, 4.7,
            4.5, 4.3, 4.1, 3.9, 4.1, 3.8, 3.7, 1.2, 0.0, 0.0, 0.0, 0.0, 3.2, 1.1, 3.2, 1.1,
            0.3, 0.6, 0.8, 0.2, 1.1, 0.9, 0.0, 0.3,1.2, 0.0, 0.0, 0.0, 0.0, 3.2, 1.1, 3.2,
            4.5, 4.3, 4.1, 3.9, 4.1, 3.8, 3.7, 1.2,0.3, 0.6, 0.8, 0.2, 1.1, 0.9, 0.0, 0.3,
        ],
        serie2: [
            0.0, 0.6, 0.8, 0.2, 1.1, 0.9, 0.0, 0.3, 0.5, 1,1, 2.5, 2.6, 2.3, 2.1, 2.9, 2.7,
            2.5, 2.3, 2.1, 1.9, 1.1, 2.8, 2.7, 1.2, 0.0, 0.0, 0.0, 0.0, 2.2, 0.1, 2.2, 0.1,
            0.3, 0.6, 0.8, 0.2, 1.1, 0.9, 0.0, 0.3,1.2, 0.0, 0.0, 0.0, 0.0, 3.2, 1.1, 3.2,
            1.5, 2.3, 1.1, 2.9, 1.1, 2.8, 1.7, 1.2,0.3, 0.6, 0.8, 0.2, 1.1, 0.9, 0.0, 0.3,
        ]
    },
    DewpointChart: {
        serie1: [
            1, 1.3, 1.5, 1.8, 2.3, 2, 7, 3.3, 3.9, 4.7, 6.8, 7.7, 7.9, 8.3, 8.5, 8.9,
            10.0, 12.1, 12.1, 12.3, 12.5, 15.3, 15.5, 16.0, 16.5, 17.0, 17.1, 17.3,
            19.1, 20.3, 20.5, 21.1, 22.9, 21.3, 19.3, 19.3, 18.2, 17.9, 16.9, 15.9,
            6.3, 6.0, 5.5, 5.2, 5.0, 4.5, 4.0, 3.9, 3.7, 3.3, 2.9, 2.7, 2.3, 2.5, 2.0,
            1.8, 1.3, 1.2, 1.1, 0.8, 0.4, 0.2, -1.9, -2.0, -2.3, -2.7, -1.1, 0.3,
        ]
    },
    UVChart: {
        serie1: [
            0, 0, 0, 0, 0, 0, 0, 0, 1, 5, 10, 30, 55, 75, 100, 101, 99, 77, 33, 22, 140, 160,
            130, 170, 210, 230, 240, 250, 260, 270, 310, 270, 340, 210, 205, 230, 240, 250, 260,
            310, 330, 350, 410, 430, 450, 470, 490, 500, 512, 490, 555, 561, 569, 566, 533, 512,
            130, 170, 210, 230, 240, 250, 260, 270, 310, 270, 340, 210, 205, 230, 240, 250, 260,
            0, 0, 0, 0, 0, 0, 0, 0, 1, 5, 10, 30, 55, 75, 100, 101, 99, 77, 33, 22, 140, 160,120
        ]
    }
}

export default WeatherChartData;