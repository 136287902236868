<template>
  <div class="dropdown align-center">
    <button
      class="btn dropdown-toggle button-dropdown-style"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ selectedText }}
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li v-if="SwitcherIncluded.day" @click="GetDailyData">
        <a class="dropdown-item" :href="href">Dnevni podatki</a>
      </li>
      <li v-if="SwitcherIncluded.week" @click="GetWeeklyData">
        <a class="dropdown-item" :href="href">Tedenski podatki</a>
      </li>
      <li v-if="SwitcherIncluded.month" @click="GetMonthlyData">
        <a class="dropdown-item" :href="href">Mesečni podatki</a>
      </li>
    </ul>
  </div>
</template>

<script>
import router from "../../../router/index";

export default {
  name: "Switcher",
  data() {
    return {
      params: router.currentRoute._value.params,
      href: null,
      selectedText: "Dnevni podatki",
    };
  },
  mounted() {
    if (this.params.stationRange === "monthly") {
      this.selectedText = "Mesečni podatki";
    } else if (this.params.stationRange === "daily") {
      this.selectedText = "Dnevni podatki";
    } else if (this.params.stationRange === "weekly") {
      this.selectedText = "Tedenski podatki";
    } else if (this.params.stationRange === "yearly") {
      this.selectedText = "Letni podatki";
    }
  },
  methods: {
    GetDailyData() {
      this.href = `/details/${this.params.stationName}/${this.params.stationId}/daily`;
      this.selectedText = "Dnevni podatki";
    },
    GetWeeklyData() {
      this.href = `/details/${this.params.stationName}/${this.params.stationId}/weekly`;
      this.selectedText = "Tedenski podatki";
    },
    GetMonthlyData() {
      this.href = `/details/${this.params.stationName}/${this.params.stationId}/monthly`;
      this.selectedText = "Mesečni podatki";
    },
    GetYearlyData() {
      this.href = `/details/${this.params.stationName}/${this.params.stationId}/yearly`;
      this.selectedText = "Letni podatki";
    },
  },
  props: {
    SwitcherIncluded: Object,
  },
};
</script>

<style scoped>
.button-dropdown-style {
  background-color: rgb(71, 180, 199);
  color: white;
  font-weight: 600;
}
</style>