<template>
    <div>
        <img class="img-fluid img-thumbnail" :src="src" alt="station_image" />
    </div>
</template>

<script>
export default {
    name: 'Image',
    props: {
        src: String
    }
}
</script>